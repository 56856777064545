import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faLinkedin, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

const Footer = () => {
    return (
        <footer>
            <span className="footer-text">Emerson Alvarado</span>
            <div className="footer-networks">
                <a href='https://github.com/emeralvarado83?tab=repositories' target='_blank'><FontAwesomeIcon icon={faGithub}/></a>
                <a href='https://www.linkedin.com/in/emerson-alvarado-2b2384203/' target="_blank"><FontAwesomeIcon icon={faLinkedin}/></a>
                <a href='https://api.whatsapp.com/send/?phone=5804126684648' target="_blank"><FontAwesomeIcon icon={faWhatsapp}/></a>
                <a href="mailto:emeralvarado1983@gmail.com"><FontAwesomeIcon icon={faEnvelope}/></a>
            </div>
        </footer>
    );
}
 
export default Footer;