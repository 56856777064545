import React from 'react';
import photoDos from '../assets/emerson-perfil-2.png';

const AboutMe = () => {

    return (
        <section className="about-me" id='about-me'>
            <h2>Sobre mí</h2>
            <div className="about-me-description">
                <p>Soy un profesional con 6 años de experiencia en la creación de sitios web con WordPress y tecnologías como Reactjs, mi enfoque principal es el desarrollo de interfaces intuitivas y atractivas que mejoren la experiencia del usuario en la web, a su vez me aseguro de un óptimo rendimiento en velocidad, estructura seo y accesibilidad.</p>
                <p>Mi objetivo es combinar tecnologías y creatividad para ofrecer soluciones web de alta calidad. ¡Echa un vistazo a mis proyectos y descubramos cómo puedo aportar valor a tu equipo o proyecto!</p>
            </div>
            <div className="about-me-photo">
                <img src={photoDos} alt=""/>
            </div>
        </section>
    );
}
 
export default AboutMe;